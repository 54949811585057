<template>
    <b-modal v-model="show" centered ok-only ok-title="Zamknij">
      <template v-slot:modal-title>
        <strong>Powrót</strong>
      </template>
      Czy na pewno chcesz zrezygnować z rejestracji?<br/>Wszystkie postępy zostaną utracone!<br/><br/>
      <b-button variant="danger" class="w-100" @click="resetSteps()">Powrót</b-button>
    </b-modal>
</template>

<script>
export default {
  name: 'ConfirmReset',
  props: {
    currentStep: {
      type: [Number, String],
      default: 2,
    },
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    resetSteps() {
      this.show = false;
      this.$emit('resetForm', true);
    },
    showModal() {
      this.show = true;
    },
  },
};
</script>
